<template>
  <modal
    :show="showModal"
    modal-classes="modal-secondary add-folder-modal"
    size="lg"
  >
    <div class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("FILES.FOLDER") }}
          </span>
          <!-- Organization -->
          <base-input
            :label="`${$t('COMMON.ORGANIZATION')} (*)`"
            :placeholder="$t('COMMON.ORGANIZATION')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
          >
            <organization-selector
              :organization="folder.organization.id"
              :filterable="true"
              :showAll="false"
              @organizationChanged="
                (organizationId) => {
                  folder.organization.id = organizationId;
                  folder.allowedLocations = [];
                  folder.users = [];
                  folder.roles = [];
                }
              "
              :disabled="!!folder.id || !!currentFolder.id"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />

          <base-input
            v-model="folder.name"
            :label="`${$t('COMMON.NAME')}`"
            :placeholder="`${$t('COMMON.NAME')}`"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.name" />

          <!-- Excerpt -->
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="folder.excerpt"> </html-editor>
          </base-input>
        </tab-pane>

        <tab-pane
          title="global"
          id="2"
          v-if="$currentUserCan($permissions.PERM_MANAGE_ACCESS_FOLDERS)"
        >
          <span slot="title">
            {{ $t("FILES.SHARE") }}
          </span>

          <!-- Locations -->
          <base-input
            :label="`${$t('COMMON.LOCATIONS')}`"
            :placeholder="$t('COMMON.LOCATIONS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <locations-selector
              :locations="folder.allowedLocations"
              :filterable="true"
              :showAll="false"
              :disabled="!folder.organization.id"
              @locationsChanged="
                (locations) => {
                  folder.allowedLocations = locations;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />

          <base-input
            :label="`${$t('COMMON.ROLES')}`"
            :placeholder="$t('COMMON.ROLES')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
          >
            <roles-selector
              :roles="folder.roles"
              :filterable="true"
              :filterOrganization="folder.organization.id"
              :disabled="!folder.organization.id"
              @rolesChanged="
                (roles) => {
                  folder.roles = roles;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.roles" />

          <base-input
            :label="`${$t('COMMON.USERS')}`"
            :placeholder="$t('COMMON.USERS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <users-selector
              :users="folder.users"
              :filterable="true"
              :filterOrganization="folder.organization.id"
              :disabled="!folder.organization.id"
              @usersChanged="
                (users) => {
                  folder.users = users;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.users" />
        </tab-pane>
      </tabs>
    </div>
    <template slot="footer">
      <button
        v-if="!folderData"
        type="submit"
        class="btn add"
        @click="addFolder"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.ADD_FOLDER") }}
      </button>

      <button
        v-if="!!folderData"
        type="submit"
        class="btn edit"
        @click="editFolder"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.EDIT_FOLDER") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto close"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import RolesSelector from "@/components/RolesSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import defaultFolder from "../defaultFolder";

export default {
  components: {
    Tabs,
    TabPane,
    HtmlEditor,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    LocationsSelector,
    RolesSelector,
    UsersSelector,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    folderData: {
      type: Object,
      default: null,
      description: "Folder Data",
    },
    currentFolder: {
      type: Object,
      default: null,
      description: "Current folder",
    },
  },

  computed: {},

  watch: {},

  data() {
    const folderData = this.getFolderData(
      this.folderData ? this.folderData : defaultFolder
    );
    return {
      loading: false,
      folder: folderData,
      folderAccessChanged: {
        allowedLocations: false,
        roles: false,
        users: false,
      },
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    getFolderData(folderData) {
      let data = cloneDeep(folderData);
      if (!data.id) {
        data.organization.id = this.currentFolder.organization.id;
        data.allowedLocations = [
          ...this.currentFolder.allowedLocations.map((item) => ({
            type: "locations",
            id: item.id,
          })),
        ];
        data.roles = [
          ...this.currentFolder.roles.map((item) => ({
            type: "roles",
            id: item.id,
          })),
        ];
        data.users = [
          ...this.currentFolder.users.map((item) => ({
            type: "users",
            id: item.id,
          })),
        ];
      }
      return data;
    },

    async addFolder() {
      this.loading = true;
      let folderData = cloneDeep(this.folder);

      const existingFolder = this.currentFolder.subfolders.find((item) => {
        return item.name.toLowerCase() === folderData.name.toLowerCase();
      });
      if (existingFolder) {
        await swal.fire({
          title: this.$t(`FILES.FOLDER_EXISTS_WITH_SAME_NAME`),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
        return;
      }

      if (this.currentFolder.id) {
        folderData.parent = {
          type: "folders",
          id: this.currentFolder.id,
        };
      } else {
        delete folderData.parent;
      }
      folderData = this.$fillUserOrganizationData(folderData);

      try {
        await this.$store.dispatch("folders/add", folderData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("FILES.FOLDER_ADDED"),
        });
        this.closeModal(true);
        this.loading = false;
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async editFolder() {
      this.loading = true;
      const folderData = cloneDeep(this.folder);

      const existingFolder = this.currentFolder.subfolders.find((item) => {
        return (
          item.name.toLowerCase() === folderData.name.toLowerCase() &&
          item.id !== folderData.id
        );
      });
      if (existingFolder) {
        await swal.fire({
          title: this.$t(`FILES.FOLDER_EXISTS_WITH_SAME_NAME`),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch("folders/update", folderData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("FILES.FOLDER_UPDATED"),
        });
        this.closeModal(true);
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.loading = false;
      }
    },
  },

  watch: {
    currentFolder: {
      handler(currentFolder) {},
      deep: true,
    },
  },
};
</script>
